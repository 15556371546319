.react-select-container {
    margin-top: 8px;
    margin-bottom: 8px;
    color: white;
}

.react-select__control {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 5px;
}

.react-select__menu {
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.react-select__option--is-focused {
    background-color: #e0e0e0;
}

.react-select__option--is-selected {
    background-color: #3f51b5;
    color: white;
}
.react-select__option {
    background-color: #939393 !important;
    opacity: 1 !important;
    color: white;
    z-index: 99999 !important;
}

.candado-container {
    min-width: 300px;
  }
  
  @media (max-width: 400px) {
    .candado-container {
      min-width: 100%;
    }
  }
  